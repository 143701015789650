import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import getMetatags from "../util/getMetatags";
import Seo, { StructuredData } from "../components/Seo";
import { WhatIsItBlock } from "../components/marketplace/blocks";
import SocialToolbar from "../components/social-toolbar";
import ArticleCard from "../components/cards/article";
import ParagraphGlossary from "../components/paragraphs/glossary";
import {
  BlockContentBlogSideBlock,
  BlockContentBlogPostSideWithoutImage,
} from "../components/blocks/content";
import { splitTitle } from "../util/glossary";

//const isProduction = `${process.env.GATSBY_ACTIVE_ENV}` === "production";

function GlossaryTerm({
  data: { nodeGlossaryItem, allNodeGlossaryItem, site },
}) {
  const { id, title, path, relationships } = nodeGlossaryItem;
  const { paragraphs, blocks, articles } = relationships;

  const seo = getMetatags(
    nodeGlossaryItem.metatag,
    nodeGlossaryItem?.relationships?.OGImage?.localFile?.publicURL
  );
  const { prev, next } = findPrevNext(id, allNodeGlossaryItem.nodes);

  const titles = splitTitle(title)
  
  return (
    <Layout showCta={false}>
      <Seo {...seo} />
      <div className="h-[70px]"></div>

      <div className="container	mx-auto  lg:mt-[70px] ">
        <div className="flex flex-row justify-between items-top gap-x-[50px] w-full ">
          <div className="flex-grow space-y-[50px]">
            <BreadCrumbs
              tip={path.alias}
              base={site.siteMetadata.siteUrl}
              title={titles.breadcrumb}
            />
            <div className="space-y-3">
              <div className="text-[#1B4DFF] bg-[#F2F5FF] rounded-[4px] text-[15px] px-2 inline-block">
                Glossary
              </div>
              <h1
                className="text-[#4F5662] text-[46px] font-light font-readexpro leading-[55px] "
                dangerouslySetInnerHTML={{ __html: titles.title }}
              />
            </div>

            <div className="border-y border-[#F0F3F9] py-4 flex flex-row justify-between items-center ">
              <div className="text-[#AFBACA]"></div>
              <div>
                <SocialToolbar className="flex flex-row items-center gap-x-[22px] text-[#AFBACA]" />
              </div>
            </div>
            
            <Paragraphs paragraphs={paragraphs} />
            
            {articles.length > 0 && (
              <Articles
                items={articles}
                className="container lg:px-0 mx-auto py-12 grid grid-cols-1 md:grid-cols-3 justify-between gap-[29px] max-w-[1108px]"
                cardClass="border border-[#D7DFE9] rounded-[10px] px-2.5 pt-2.5 pb-[30px] bg-white space-y-5"
                Card={ArticleCard}
              />
            )}
            <div className=" mx-auto space-y-16 mb-20 print:hidden">
              <WhatIsItBlock />
            </div>
            <Pagination next={next} previous={prev} className="print:hidden" />
          </div>
          <div className="min-w-[268px] max-w-[268px] hidden print:hidden lg:block">
            <section className=" sticky top-[108px] space-y-8 --mt-[104px]">
              {blocks &&
                blocks.map((block, index) => (
                  <Component data={block} key={index} />
                ))}
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const getCategory = (article) => {
  let category = "-";

  const type = article.__typename;

  //console.log(type)
  switch (type) {
    case "node__blog":
      category = article.relationships?.category?.name;
      break;
    case "node__ebook":
      category = article.type;
      break;

    default:
      category = "--";
      break;
  }

  return category;
};

const Articles = ({ items, className, cardClass, Card }) => {
  const cards = items
    .map((article) => ({
      ...article,
      image: {
        ...article.image,
        src: article.relationships?.image?.localFile?.publicURL,
      },
      category: getCategory(article),
    }))
    .map((article, index) => (
      <Card key={index} {...article} className={cardClass} />
    ));

  return <div className={className}>{cards}</div>;
};
const findPrevNext = (id, items) => {
  return {
    prev: findPrev(id, items),
    next: findNext(id, items),
  };
};

const findNext = (id, items) => {
  const items2 = [...items, items[0]];
  const index = items2.findIndex((e) => e.id === id);
  return items2[index + 1];
};

const findPrev = (id, items) => {
  return findNext(id, [...items].reverse());
};

const BreadCrumbs = ({ tip, base, title }) => {
  //console.log(base)
  //const hpUrl = base + "/";

  const jsonLD = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [],
  };

  jsonLD.itemListElement.push({
    "@type": "ListItem",
    position: 1,
    name: "Home",
    item: base + "/",
  });

  jsonLD.itemListElement.push({
    "@type": "ListItem",
    position: 2,
    name: "Glossary",
    item: base + "/glossary/",
  });

  jsonLD.itemListElement.push({
    "@type": "ListItem",
    position: 3,
    name: title,
  });

  return (
    <>
      <section className="">
        <div className="container  flex  px-0 text-[#3D434C] text-sm">
          <Link className=" mr-4" to={"/"}>
            Home
          </Link>
          <span className="mr-4 ">{" / "}</span>
          <Link className=" mr-4" to={"/glossary/"}>
            Glossary
          </Link>
          <span className="mr-4 ">{" / "}</span>
          <div className="font-medium">{title}</div>
        </div>
      </section>
      <StructuredData>{[{ ...jsonLD }]}</StructuredData>
    </>
  );
};

export default GlossaryTerm;

const Paragraphs = ({ paragraphs, ...props }) => {
  return (
    <section className="-py-10 ">
      <div className="container px-0 max-w-[1200px]">
        {paragraphs.map((paragraph, index) => (
          <Component data={paragraph} key={index} />
          // <Paragraph {...paragraph} key={index} />
        ))}
      </div>
    </section>
  );
};

const Pagination = ({ next, previous, className }) => {
  const nextPath = next.path.alias + "/";
  const prevPath = previous.path.alias + "/";
  return (
    <section className={className + " pb-32 "}>
      <div className=" flex flex-row  justify-between px-0">
        <div>
          <div className="flex flex-row items-center  space-x-2">
            <div className="text-black text-base font-medium 	">
              <Pagination.Link to={prevPath} title={"Previous"} />
            </div>
          </div>
          <div className="text-[#4F5662] pl-[17px]">{previous.title.split('<br>')[0]}</div>
        </div>
        <div className="w-fit text-right">
          <div className="flex flex-row items-center justify-end  space-x-2 text-right  w-full ">
            <div className="text-black text-base font-medium text-right	">
              <Pagination.Link to={nextPath} title={"Next"} reverse={true} />
            </div>
          </div>
          <div className="text-[#4F5662] pr-[17px] text-right ">
            {next.title.split('<br>')[0]}
          </div>
        </div>
      </div>
    </section>
  );
};

Pagination.Link = ({ to, title, reverse = false }) => {
  const inner = reverse ? (
    <>
      <span>{title}</span>
      <span
        className=" "
        width="9px"
        dangerouslySetInnerHTML={{ __html: arrow }}
      />
    </>
  ) : (
    <>
      <span
        className=" rotate-180 "
        width="9px"
        dangerouslySetInnerHTML={{ __html: arrow }}
      />
      <span>{title}</span>
    </>
  );
  return (
    <Link
      to={to}
      className="hover:bg-[#F9F8F7] rounded-[10px] px-5 py-[15px] flex flex-row justify-between items-center gap-x-3 text-[#3D434C]"
    >
      {inner}
    </Link>
  );
};

const arrow = `<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.58896 7.99982L0.812715 3.22357C0.295215 2.70607 0.295215 1.86607 0.812715 1.34857C1.33021 0.831074 2.17022 0.831074 2.68772 1.34857L8.45521 7.11607C8.94396 7.60483 8.94396 8.39607 8.45521 8.88357L2.68772 14.6511C2.17022 15.1686 1.33021 15.1686 0.812715 14.6511C0.295215 14.1336 0.295215 13.2936 0.812715 12.7761L5.58896 7.99982Z" fill="#4F5662"/>
</svg>
`;

const Component = ({ data, context, ...props }) => {
  //console.log( data )
  let comp = "";

  switch (data.__typename) {
    case "paragraph__glossary":
      comp = <ParagraphGlossary data={data} context={context} {...props} />;
      break;

    case "block_content__blog_side_block":
      comp = (
        <BlockContentBlogSideBlock data={data} context={context} {...props} />
      );
      break;

    case "block_content__blog_post_side_without_image":
      comp = (
        <BlockContentBlogPostSideWithoutImage
          data={data}
          context={context}
          {...props}
        />
      );
      break;

    default:
      comp = <pre>{JSON.stringify(data, null, 3)}</pre>;
      break;
  }

  return <>{comp}</>;
};

export const query = graphql`
  query glossaryItem($id: String!) {
    nodeGlossaryItem(id: { eq: $id }) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path {
        alias
      }
      color: field_color {
        color
      }
      relationships {
        OGImage: field_image {
          localFile {
            publicURL
          }
        }
        paragraphs: field_paragraph_repeter {
          ...ParagraphGlossary
        }
        blocks: field_side_blocks {
          ... on block_content__blog_side_block {
            ...BlogSideBlockContent
          }
          ... on block_content__blog_post_side_without_image {
            ...BlogSideBlockNoImageContent
          }
        }
        articles: field_articles {
          ... on node__blog {
            ...NodeBlogTeaser
          }
          ... on node__ebook {
            ...NodeEbookTeaser
          }
        }
      }
    }
    allNodeGlossaryItem {
      nodes {
        id
        title
        path {
          alias
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
